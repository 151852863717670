import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import { collection, query, where, getDocs, doc, deleteDoc, getDoc, setDoc, updateDoc, orderBy } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage'; // Firebase storage functions
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas from qrcode.react
import { auth, db, storage } from './firebase'; // Firebase setup
import { FaInstagram } from 'react-icons/fa'; // Import Instagram icon
import Header from './Header'; // Import Header component
import { v4 as uuidv4 } from 'uuid';
import { toast, ToastContainer } from 'react-toastify'; // Import Toast components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

import DOMPurify from 'dompurify';

function CarDetails() {
  const { id } = useParams(); // Extract the car ID from the URL
  const [carInfo, setCarInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const [isFavorite, setIsFavorite] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [teamsForCar, setTeamsForCar] = useState([]);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const navigate = useNavigate();
  const pageLocation = useLocation();

  useEffect(() => {
    const fetchCarInfo = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'profiles', id); // Get the document by ID
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (!data.driverName || !data.carModel) {
            // No profile data exists, check if the user is logged in
            if (!auth.currentUser) {
              // Not logged in, redirect to login and then to Add Profile page
              navigate(`/login?redirect=/create-profile/${id}`);
            } else {
              // Logged in, redirect to Add Profile page
              navigate(`/create-profile/${id}`);
            }
          } else {
            setCarInfo(data); // Set profile data if it exists'
            const fetchTeamsForCar = async () => {
              try {
                // Fetch teams where the car profile (identified by `id`) is a member
                const q = query(collection(db, 'teams'), where('carProfiles', 'array-contains', id));
                const querySnapshot = await getDocs(q);
                const teams = querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                setTeamsForCar(teams);

                // Fetch all teams the user is a part of, to give options for adding/removing
                if (auth.currentUser) {
                  const userId = auth.currentUser.uid;
                  const userTeamsQuery = query(collection(db, 'teams'), where('members', 'array-contains', userId));
                  const userTeamsSnapshot = await getDocs(userTeamsQuery);
                  const userTeams = userTeamsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                  }));
                  setAvailableTeams(userTeams);
                }
              } catch (error) {
                console.error('Error fetching teams for car:', error);
              }
            };
            fetchTeamsForCar();
            if (auth.currentUser) {
              const userId = auth.currentUser.uid;
              if (userId === data.userId) {
                setIsOwner(true);
                checkIfFavorite(userId, id);
              } else {
                if (auth.currentUser) {
                  recordScanUser(userId, id);
                  checkIfFavorite(userId, id);
                }
                recordScan(id);

              }
            }
            else {
              recordScan(id);
            }
          }
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching car data:', error);
      } finally {
        setLoading(false);

      }
    };
    fetchCarInfo();
  }, [id, navigate]);
  const recordScanUser = async (userId, carId) => {
    try {
      const scanDocRef = doc(db, 'users', userId, 'activity', 'recentScans');
      const scanSnap = await getDoc(scanDocRef);
      let scans = scanSnap.exists() ? scanSnap.data().scans : [];
      scans = [{ id: carId, timestamp: new Date() }, ...scans.filter(scan => scan.id !== carId)].slice(0, 10); // Keep latest 10 scans
      await setDoc(scanDocRef, { scans });
      console.log('Scan recorded successfully.');
    } catch (error) {
      console.error('Error recording scan for user:', error);
    }
    try {
      const scanRef = doc(db, 'users', userId, 'activity', 'scanCounts');
      const scanSnap = await getDoc(scanRef);
      let scanData = scanSnap.exists() ? scanSnap.data() : { uniqueScans: [] };

      // Check if this car has been scanned before
      if (!scanData.uniqueScans.includes(carId)) {
        scanData.uniqueScans.push(carId);
        await setDoc(scanRef, { uniqueScans: scanData.uniqueScans });

        // Fetch all achievements from the 'achievements' collection
        const achievementsSnapshot = await getDocs(collection(db, 'achievements'));
        const achievementsList = achievementsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Check if the user qualifies for any achievements based on their scan count
        for (const achievement of achievementsList) {
          if (
            achievement.NumberOfScansRequired &&
            scanData.uniqueScans.length === achievement.NumberOfScansRequired
          ) {
            const userAchievementRef = doc(db, 'users', userId, 'achievements', achievement.id);
            const userAchievementSnap = await getDoc(userAchievementRef);

            // If the achievement hasn't been reached yet, record it
            if (!userAchievementSnap.exists()) {
              await recordAchievement(userId, achievement.id);
              toast.success(`Congratulations! You unlocked the "${achievement.name}" achievement!`);
            }

          }
        }
      }
    } catch (error) {
      console.error('Error tracking scan activity:', error);
    }


  };
  const recordAchievement = async (userId, achievementId) => {
    try {
      const achievementsRef = doc(db, 'users', userId, 'achievements', 'earned');
      const achievementsSnap = await getDoc(achievementsRef);

      let userAchievements = [];
      if (achievementsSnap.exists()) {
        userAchievements = achievementsSnap.data().achievements || [];
      }

      // Check if the achievement is already earned
      if (!userAchievements.includes(achievementId)) {
        userAchievements.push(achievementId);
        await setDoc(achievementsRef, { achievements: userAchievements }, { merge: true });
        console.log('Achievement recorded:', achievementId);
      }
    } catch (error) {
      console.error('Error recording achievement:', error);
    }
  };
  const recordScan = async (carId) => {
    try {
      // Check for an existing unique ID for the visitor
      let visitorId = localStorage.getItem('visitorId');
      if (!visitorId) {
        // Generate and store a unique ID if not present
        visitorId = uuidv4();
        localStorage.setItem('visitorId', visitorId);
      }

      // Reference to the scanStats document for the specific car profile
      const scanStatsRef = doc(db, 'scanStats', carId);
      const scanStatsSnap = await getDoc(scanStatsRef);

      if (scanStatsSnap.exists()) {
        const scanData = scanStatsSnap.data();
        const updatedTotalScans = (scanData.totalScans || 0) + 1;
        const uniqueVisitors = scanData.uniqueVisitors || {};

        // Check if the current visitor is unique
        if (!uniqueVisitors[visitorId]) {
          uniqueVisitors[visitorId] = new Date().toISOString(); // Store the timestamp of the visit
        }

        await updateDoc(scanStatsRef, {
          totalScans: updatedTotalScans,
          uniqueVisitors: uniqueVisitors,
        });
      } else {
        // Create a new document if it doesn't exist
        await setDoc(scanStatsRef, {
          totalScans: 1,
          uniqueVisitors: {
            [visitorId]: new Date().toISOString(),
          },
        });
      }

      console.log('Scan and visitor recorded successfully.');
    } catch (error) {
      console.error('Error recording scan:', error);
    }

  };
  const checkIfFavorite = async (userId, carId) => {
    try {
      const favoritesDocRef = doc(db, 'users', userId, 'activity', 'favorites');
      const favoritesSnap = await getDoc(favoritesDocRef);
      const favorites = favoritesSnap.exists() ? favoritesSnap.data().favorites : [];
      setIsFavorite(favorites.some(fav => fav.id === carId));
    } catch (error) {
      console.error('Error checking favorite status:', error);
    }
  };
  const handleAddCarToTeam = async () => {
    if (!selectedTeam) {
      alert('Please select a team.');
      return;
    }

    try {
      // Add the car profile to the selected team's carProfiles array
      const teamRef = doc(db, 'teams', selectedTeam);
      const teamSnap = await getDoc(teamRef);
      if (teamSnap.exists()) {
        const teamData = teamSnap.data();
        const updatedCarProfiles = teamData.carProfiles ? [...teamData.carProfiles, id] : [id];
        await updateDoc(teamRef, { carProfiles: updatedCarProfiles });

        // Update local state
        setTeamsForCar(prev => [...prev, { id: selectedTeam, ...teamData }]);
        alert(`Car profile added to team: ${teamData.teamName}`);
      }
    } catch (error) {
      console.error('Error adding car to team:', error);
      alert('Error adding car to team. Please try again.');
    }
  };

  const handleRemoveCarFromTeam = async (teamId) => {
    try {
      // Remove the car profile from the selected team's carProfiles array
      const teamRef = doc(db, 'teams', teamId);
      const teamSnap = await getDoc(teamRef);
      if (teamSnap.exists()) {
        const teamData = teamSnap.data();
        const updatedCarProfiles = teamData.carProfiles ? teamData.carProfiles.filter(profileId => profileId !== id) : [];
        await updateDoc(teamRef, { carProfiles: updatedCarProfiles });

        // Update local state
        setTeamsForCar(prev => prev.filter(team => team.id !== teamId));
        alert(`Car profile removed from team: ${teamData.teamName}`);
      }
    } catch (error) {
      console.error('Error removing car from team:', error);
      alert('Error removing car from team. Please try again.');
    }
  };
  const handleAddToFavorites = async () => {
    if (!auth.currentUser) {
      navigate('/login');
      return;
    }

    try {
      const favoritesDocRef = doc(db, 'users', auth.currentUser.uid, 'activity', 'favorites');
      const favoritesSnap = await getDoc(favoritesDocRef);
      const favorites = favoritesSnap.exists() ? favoritesSnap.data().favorites : [];

      if (isFavorite) {
        // Remove from favorites
        const updatedFavorites = favorites.filter(fav => fav.id !== id);
        await setDoc(favoritesDocRef, { favorites: updatedFavorites });
        setIsFavorite(false);
      } else {
        // Add to favorites
        const newFavorite = { id, carModel: carInfo.carModel, timestamp: new Date() };
        const updatedFavorites = [newFavorite, ...favorites];
        await setDoc(favoritesDocRef, { favorites: updatedFavorites });
        setIsFavorite(true);
      }

      console.log('Favorites updated successfully.');
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };
  if (loading) {
    return <div>
      <Header /><div className="flex items-center justify-center min-h-screen">
        <div className="loader text-black">Loading...</div>
        {/* Replace with any custom loader/spinner component or text */}
      </div></div>;
  }

  if (!carInfo) {
    return;
  }

  // URL to the driver's profile page
  const profileURL = `https://www.fandrive.net/car-details/${id}`;

  // Construct Instagram and Twitter URLs
  const instagramURL = carInfo.socialMediaLinks?.instagram
    ? `https://www.instagram.com/${carInfo.socialMediaLinks.instagram}`
    : null;

  const twitterURL = carInfo.socialMediaLinks?.twitter
    ? `https://twitter.com/${carInfo.socialMediaLinks.twitter}`
    : null;

  // Function to handle sharing
  const handleShare = async () => {
    if (!carInfo || !carInfo.carPicture) {
      alert('Car image not available for sharing.');
      return;
    }

    try {
      const carImage = new Image();
      carImage.crossOrigin = 'anonymous'; // Prevent CORS issues
      carImage.src = carInfo.carPicture;

      carImage.onload = async () => {
        // Create a canvas
        const canvas = document.createElement('canvas');
        canvas.width = carImage.width;
        canvas.height = carImage.height;
        const ctx = canvas.getContext('2d');

        // Draw the car image on the canvas
        ctx.drawImage(carImage, 0, 0, carImage.width, carImage.height);

        // QR code size and position
        const qrSize = 150;
        const qrX = carImage.width - qrSize - 20; // Left padding
        const qrY = carImage.height - qrSize - 40; // Bottom padding with extra space for text

        // Draw a black box for the QR code with a border
        ctx.fillStyle = 'black';
        ctx.fillRect(qrX - 10, qrY - 10, qrSize + 20, qrSize + 40); // Box size including border and text space

        ctx.strokeStyle = 'white'; // Border color
        ctx.lineWidth = 2;
        ctx.strokeRect(qrX - 10, qrY - 10, qrSize + 20, qrSize + 40);

        // Draw the QR code onto the canvas
        const qrCanvas = document.querySelector('canvas'); // Assuming there's a QR code canvas element
        if (qrCanvas) {
          ctx.drawImage(qrCanvas, qrX, qrY, qrSize, qrSize);
        }

        // Draw the "FanDrive" text below the QR code
        ctx.fillStyle = 'white';
        ctx.font = '20px Arial';
        ctx.textAlign = 'center';
        ctx.fillText('FanDrive', qrX + qrSize / 2, qrY + qrSize + 25);

        // Convert the canvas to an image and share
        const imgData = canvas.toDataURL('image/png');
        const blob = await (await fetch(imgData)).blob();
        const file = new File([blob], 'car_with_qr.png', { type: 'image/png' });

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: 'Check out this car on FanDrive!',
            files: [file],
            text: `Scan the QR code or click the link to view more details: ${window.location.href}`, // Add URL here
          });
        } else {
          alert('Sharing is not supported on this device/browser.');
        }
      };
    } catch (error) {
      console.error('Error sharing the image with QR code:', error);
    }
  };
  const handleEdit = (profileId) => {
    navigate(`/edit-profile/${profileId}`);
  };
  const handleBack = () => {
    if (pageLocation.state?.fromTeamManagement) {
        // Navigate back to TeamManagement if it was accessed from there
        navigate(`/team-management/${pageLocation.state.teamId}`);
    } else {
        // Default behavior: Navigate back to UserProfiles or another default page
        navigate('/user-profiles');
    }
};
  const handleDeleteProfile = async (profileId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this profile? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, 'profiles', profileId));

      const profilePicRef = ref(storage, `profiles/${profileId}-profile-pic`);
      const carPicRef = ref(storage, `cars/${profileId}-car-pic`);
      try {
        await deleteObject(profilePicRef);
        await deleteObject(carPicRef);
        console.log('Profile and associated images deleted successfully');
      } catch (storageError) {
        console.error('Error deleting images:', storageError);
      }
      navigate('/user-profiles');
      //setProfiles((prevProfiles) => prevProfiles.filter((profile) => profile.id !== profileId));
    } catch (error) {
      console.error('Error deleting profile and images:', error);
    }
  };
  


  return (
    <div>
      <Header />

      {auth.currentUser && (
        <div className="mt-4 bg-white text-black">

          <button
          onClick={handleBack}
            className="flex items-center space-x-2 text-black text-lg bg-transparent hover:bg-gray-800 transition duration-200 ease-in-out px-3 py-1 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 4a1 1 0 00-1.707-.707L1.586 10l6.707 6.707A1 1 0 0010 15.414l-5-5a1 1 0 010-1.414l5-5z"
                clipRule="evenodd"
              />
            </svg>
            <span>Back</span>
          </button>
        </div>
      )}
      <div className="min-h-screen bg-white text-black flex flex-col items-center justify-center py-8 px-4">
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar closeOnClick pauseOnHover />
        {/* Car Image */}

        <div className="mb-6 w-full max-w-lg">
          <img
            src={carInfo.carPicture} // Fetching car image URL from Firestore
            alt={`Car ${carInfo.carModel}`}
            className="w-full rounded-lg"
          />
        </div>
        {isOwner && (
          <div className="flex justify-center space-x-4 mt-4 pb-6">
            <button
              onClick={() => handleEdit(id)}
              className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-200 ease-in-out shadow-md focus:outline-none focus:ring-2 focus:ring-yellow-300"
            >
              Edit Profile
            </button>
            <button
              onClick={() => handleDeleteProfile(id)}
              className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition duration-200 ease-in-out shadow-md focus:outline-none focus:ring-2 focus:ring-red-300"
            >
              Delete Profile
            </button>
          </div>
        )}
        {isOwner && (
          <div className="mt-6">
            {/* Display teams the car is currently part of only if there are any */}
            {teamsForCar.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Current Teams</h3>
                <ul className="list-disc pl-5">
                  {teamsForCar.map(team => (
                    <li key={team.id}>
                      {team.teamName}
                      <button
                        onClick={() => handleRemoveCarFromTeam(team.id)}
                        className="ml-4 text-red-500 hover:underline"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Display "Add to a Team" section only if the user has available teams */}
            {isOwner && availableTeams.filter(team => !teamsForCar.some(t => t.id === team.id)).length > 0 && (
              <div>
                <h3 className="text-lg font-semibold mt-4">Add to a Team</h3>
                <select
                  value={selectedTeam}
                  onChange={(e) => setSelectedTeam(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg mb-4"
                >
                  <option value="">-- Select a Team --</option>
                  {availableTeams
                    .filter(team => !teamsForCar.some(t => t.id === team.id)) // Filter out teams the car is already part of
                    .map(team => (
                      <option key={team.id} value={team.id}>
                        {team.teamName}
                      </option>
                    ))}
                </select>
                <button
                  onClick={handleAddCarToTeam}
                  className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white"
                  disabled={!selectedTeam}
                >
                  Add to Team
                </button>
              </div>
            )}
          </div>
        )}

        {/* Driver Info and Image */}
        <div className="border-4  rounded-lg p-6 max-w-md w-full text-center" style={{ borderColor: '#00AEEF' }}>
          <img
            src={carInfo.profilePicture != '' ? carInfo.profilePicture : `${process.env.PUBLIC_URL}/RaceDriverSilhouette.png`} // Fetching driver image URL from Firestore
            alt={`Driver ${carInfo.driverName}`}
            className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
          />
          <h1 className="text-4xl font-bold mb-4" style={{ color: '#00AEEF' }}>{carInfo.driverName}</h1>
          <p className="text-lg font-semibold mb-2"><span className="text-red-500">{carInfo.carModel}</span>
          </p>
          <p
            className="text-md text-gray-700 mb-4"
            dangerouslySetInnerHTML={{ __html: carInfo.description ? DOMPurify.sanitize(carInfo.description.replace(/\n/g, '<br />')) : 'No description available.' }}
          ></p>

          {/* Social Media Links */}
          <div className="flex justify-center space-x-4 mb-6 ">
            {instagramURL && (
              <a
                href={instagramURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:opacity-80 transition border border-4 rounded-lg border-black bg-black"
              >
                <FaInstagram size={32} />
              </a>
            )}
            {twitterURL && (
              <a
                href={twitterURL}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black border border-4 rounded-lg border-black bg-black hover:opacity-80 transition"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/x-logo-white.png`} // Path to the X logo in the public folder
                  alt="X logo"
                  width={32}
                  height={32}
                  className="inline"
                />
              </a>
            )}
          </div>

          {/* YouTube Video */}
          {carInfo.youtubeVideo && (
            <div className="mb-6 border-4 border-white-600 rounded-lg">
              <iframe
                width="100%"
                height="315"
                src={carInfo.youtubeVideo}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="rounded-lg"
              ></iframe>
            </div>
          )}

          {/* QR Code for the profile */}
          <div className="mt-6">
            {/* <h3 className="text-2xl font-bold mb-4">Share this Profile</h3> */}
            <div className="flex justify-center">
              <QRCodeCanvas value={profileURL} size={200} />
            </div>
          </div>

          {/* Share Button */}
          <div className="text-center mt-6">
            <button
              onClick={handleShare}
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
            >
              Share Profile
            </button>
          </div>
          {/* Add to Favorites Button */}
          {auth.currentUser && (
            <button
              onClick={handleAddToFavorites}
              className={`mt-4 py-2 px-4 rounded-lg ${isFavorite ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
                } text-white`}
            >
              {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
            </button>
          )}
        </div>

      </div>

    </div>

  );

}

export default CarDetails;
