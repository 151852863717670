// firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyC9PFVBjcEIEu9wpZs0d1PLyKUmBpMTJTA",
    authDomain: "fandrive-c0d4e.firebaseapp.com",
    projectId: "fandrive-c0d4e",
    storageBucket: "fandrive-c0d4e.appspot.com",
    messagingSenderId: "74243834359",
    appId: "1:74243834359:web:48c087f95bf875b32caef2",
    measurementId: "G-449N4V5E8E"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
