import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function Home() {
  const navigate = useNavigate(); // Hook to programmatically navigate

  return (
    
    <div className="min-h-screen flex flex-col justify-between bg-white text-gray-800" style={{color:"#00AEEF"}}>
      {/* Header */}
      <header className="border-b-4 border-blue-300 text-center py-6">
        {/* FanDrive Logo */}
        <img
          src={`${process.env.PUBLIC_URL}/fandrive-sm.png`} // Path to fandrive.png in the public folder
          alt="FanDrive Logo"
          className="mx-auto mb-4" style={{width:'300px'}} // Adjust size as necessary
        />
        {/* <p className="text-lg mt-2 text-blue-700">Exclusive access to cars and drivers</p> */}
      </header>

      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="bg-gradient-to-br from-blue-50 to-white border border-blue-200 rounded-lg p-8 mb-8 shadow-lg hover:shadow-xl transition duration-300">
          <h2 className="text-3xl font-semibold mb-4 ">How FanDrive Works</h2>
          <p className="text-xl mb-6" style={{color:'black'}}>
            Use your phone's camera to scan the QR code on any car to unlock exclusive driver details, car specs, and videos.
          </p>
          <hr className="border-blue-200 mb-6" />
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-white border border-blue-200 rounded-lg p-6 mb-6 shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-2xl font-bold mb-2 ">Step 1: Open Your Camera</h3>
          <p className="text-lg" style={{color:'black'}}>
            Point your phone's camera at the QR code on the car.
          </p>
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-white border border-blue-200 rounded-lg p-6 mb-6 shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-2xl font-bold mb-2 ">Step 2: Tap the Link</h3>
          <p className="text-lg" style={{color:'black'}}>
            Once the QR code is scanned, tap the link to see the car and driver details.
          </p>
        </div>
        <div className="bg-gradient-to-br from-blue-50 to-white border border-blue-200 rounded-lg p-6 shadow-md hover:shadow-lg transition duration-300">
          <h3 className="text-2xl font-bold mb-2">Optional: Create an Account</h3>
          <p className="text-lg" style={{color:'black'}}>
            Create a free account to create your own car profiles to share or to save your favorite cars and drivers.
          </p>
        </div>

        {/* New Buttons for Log In, Sign Up, and Create Profile */}
        <div className="mt-10">
          <button
            onClick={() => navigate('/login')}
            className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 mb-4 mx-2 shadow-lg hover:shadow-xl transition duration-300"
          >
            Log In
          </button>

          <button
            onClick={() => navigate('/signup')}
            className="bg-blue-400 text-white py-2 px-4 rounded-full hover:bg-blue-500 mb-4 mx-2 shadow-lg hover:shadow-xl transition duration-300"
          >
            Sign Up
          </button>

          {/* <button
            onClick={() => navigate('/create-profile')}
            className="bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 mx-2 shadow-lg hover:shadow-xl transition duration-300"
          >
            Create Driver Profile
          </button> */}
        </div>
      </main>

      {/* Footer */}
      <footer className="text-center py-6 border-t-4 border-blue-300">
        <p>Powered by FanDrive &copy; 2024</p>
        {/* <a href="#" className="text-blue-500 underline">Follow Us on Social Media</a> */}
      </footer>
    </div>
  );
}

export default Home;