// AcceptInvitation.js
import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase'; // Adjust import paths if necessary
import { useParams, useNavigate } from 'react-router-dom';
import { getDoc, doc, updateDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

function AcceptInvitation() {
    const { invitationId } = useParams(); // Assuming you're using URL parameters
    const [invitation, setInvitation] = useState(null);
    const [user, setUser] = useState(null);
    const [carProfiles, setCarProfiles] = useState([]);
    const [selectedCarProfile, setSelectedCarProfile] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInvitation = async () => {
            try {
                const docRef = doc(db, 'invitations', invitationId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setInvitation({ id: docSnap.id, ...docSnap.data() });
                } else {
                    alert('Invitation not found.');
                    navigate('/');
                }
            } catch (error) {
                console.error('Error fetching invitation:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchUser = () => {
            onAuthStateChanged(auth, async (currentUser) => {
                if (currentUser) {
                    setUser(currentUser);
                    fetchCarProfiles(currentUser);
                }
            });
        };

        const fetchCarProfiles = async (currentUser) => {
            try {
                const q = query(collection(db, 'profiles'), where('userId', '==', currentUser.uid));
                const querySnapshot = await getDocs(q);
                const profiles = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setCarProfiles(profiles);
            } catch (error) {
                console.error('Error fetching car profiles:', error);
            }
        };

        fetchInvitation();
        fetchUser();
    }, [invitationId, navigate]);

    const handleAccept = async () => {
        if (!user) {
            alert('You need to log in or create an account to accept this invitation.');
            navigate('/login');
            return;
        }

        if (!selectedCarProfile) {
            alert('Please select a car profile to associate with this team.');
            return;
        }

        try {
            // Update the invitation status and store the user's display name
            const invitationRef = doc(db, 'invitations', invitationId);
            await updateDoc(invitationRef, {
                status: 'accepted'
                // displayName: user.displayName || 'No Display Name' // Store the display name or a fallback
            });

            // Update the team's member list and carProfiles array
            const teamRef = doc(db, 'teams', invitation.teamId);
            const teamSnap = await getDoc(teamRef);
            if (teamSnap.exists()) {
                const teamData = teamSnap.data();
                const updatedMembers = teamData.members ? [...teamData.members, user.uid] : [user.uid];
                const updatedCarProfiles = teamData.carProfiles ? [...teamData.carProfiles, selectedCarProfile] : [selectedCarProfile];

                await updateDoc(teamRef, {
                    members: updatedMembers,
                    carProfiles: updatedCarProfiles
                });

                alert('You have successfully joined the team!');
                navigate('/user-profiles'); // Redirect to the user profiles page or a relevant page
            } else {
                alert('Team not found.');
            }
        } catch (error) {
            console.error('Error accepting invitation:', error);
            alert('Error accepting the invitation. Please try again.');
        }
    };

    const handleReject = async () => {
        try {
            // Update the invitation status to 'rejected'
            const invitationRef = doc(db, 'invitations', invitationId);
            await updateDoc(invitationRef, { status: 'rejected' });

            alert('Invitation rejected.');
            navigate('/user-profiles'); // Redirect after rejecting
        } catch (error) {
            console.error('Error rejecting invitation:', error);
            alert('Error rejecting the invitation. Please try again.');
        }
    };

    return (
        <div className="p-4 max-w-lg mx-auto bg-white rounded-lg shadow-md">
            {loading ? (
                <div>Loading...</div>
            ) : invitation ? (
                <div>
                    <h2 className="text-2xl font-bold mb-4">Accept Invitation</h2>
                    <p>You have been invited to join the team: {invitation.teamName}</p>
                    {user ? (
                        <>
                            <h3 className="text-lg font-semibold mt-4">Select a Car Profile</h3>
                            {carProfiles.length > 0 ? (
                                <select
                                    value={selectedCarProfile}
                                    onChange={(e) => setSelectedCarProfile(e.target.value)}
                                    className="w-full px-3 py-2 border rounded-lg mb-4"
                                >
                                    <option value="">-- Select a Car Profile --</option>
                                    {carProfiles.map((profile) => (
                                        <option key={profile.id} value={profile.id}>
                                            {profile.carModel} (Driver: {profile.driverName})
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <p>You have no car profiles. Please <a href="/create-profile" className="text-blue-500 underline">create one</a> before accepting.</p>
                            )}
                            <button
                                onClick={handleAccept}
                                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 mr-2"
                                disabled={!selectedCarProfile}
                            >
                                Accept
                            </button>
                            <button
                                onClick={handleReject}
                                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                            >
                                Reject
                            </button>
                        </>
                    ) : (
                        <p>
                            Please <a href="/login" className="text-blue-500 underline">log in</a> or <a href="/register" className="text-blue-500 underline">create an account</a> to proceed.
                        </p>
                    )}
                </div>
            ) : (
                <div>Invitation not found.</div>
            )}
        </div>
    );
}

export default AcceptInvitation;