// sendInvitation.js
// var postmark = require("postmark");

// Send an email:
// var client = new postmark.ServerClient('07a3b66a-ab11-4c31-a794-34a0c6da2cd0');

async function sendInvitationEmail(email, teamName, inviteid) {
    try {
        const htmlContent = `
            <div style="font-family: Arial, sans-serif; line-height: 1.5; color: #333;">
                <div style="text-align: center; margin-bottom: 20px;">
                    <img src="https://www.fandrive.net/fandrive.png" alt="FanDrive Logo" style="max-width: 150px; height: auto;" />
                </div>
                <div style="padding: 20px; border: 1px solid #e0e0e0; border-radius: 5px;">
                    <h2 style="color: #00AEEF; text-align: center;">You're Invited to Join the Team: ${teamName}</h2>
                    <p>Hello,</p>
                    <p>You have been invited to join the team <strong>${teamName}</strong> on FanDrive. Click the button below to accept the invitation:</p>
                    <div style="text-align: center; margin: 20px 0;">
                        <a href="https://www.fandrive.net/accept-invite/${inviteid}" style="background-color: #00AEEF; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; display: inline-block;">Accept Invitation</a>
                    </div>
                    <p>If you have any questions or didn't expect this invitation, please contact us at <a href="mailto:support@fandrive.net">support@fandrive.net</a>.</p>
                    <p>Thank you,<br>The FanDrive Team</p>
                </div>
            </div>
        `;
    

            // const postData = {
            //     From: 'team-invitation@fandrive.net', // Replace with your Postmark sender email
            //     To: email,
            //     Subject: `You're Invited to Join the Team: ${teamName}`,
            //     HtmlBody: htmlContent,
            //     TextBody: `You have been invited to join the team "${teamName}". Click the link to accept the invitation: https://www.fandrive.net/accept-invite/${teamId}`,
            // };
            // const response = await fetch('https://api.postmarkapp.com/email', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'X-Postmark-Server-Token': '07a3b66a-ab11-4c31-a794-34a0c6da2cd0'
            //     },
            //     body: JSON.stringify(postData)
            // });
    
            // if (!response.ok) {
            //     throw new Error(`HTTP error! status: ${response.status}`);
            // }
    
            // console.log('Email sent successfully');

            fetch('https://prod-02.northcentralus.logic.azure.com:443/workflows/549a5c8e39e74034b531d69aabfc9361/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=lh-l5LfnUA-4ztphYt_r3QGkr5f52b6zzOLzZl4oDD0', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  From: 'team-invitation@fandrive.net',
                  To: email,
                  Subject: `You're Invited to Join the Team: ${teamName}`,
                  TextBody: `You have been invited to join the team "${teamName}". Click the link to accept the invitation: https://www.fandrive.net/accept-invite/${inviteid}`,
                  HtmlBody: htmlContent
                })
              })
              .then(response => response.json())
              .then(data => console.log('Success:', data))
              .catch((error) => console.error('Error:', error));

            
    } catch (error) {
        console.error('Error sending email:', error);
    }
}

export default sendInvitationEmail;