import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'; // Import the Home component
import LoginPage from './Login';
import SignUp from './SignUp'; // Import SignUp component
import CreateProfile from './CreateProfile'; // Import CreateProfile component
import UserProfiles from './UserProfiles'; // Import UserProfiles component
import CarDetails from './CarDetails'; // Import CarDetails component
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute for protected pages
import AdminGenerateQR from './AdminGenerateQRs';
import TeamProfile from './TeamProfile';
import TeamManagement from './TeamManagement';
import AcceptInvitation from './AcceptInvitation';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home page */}
        <Route path="/login" element={<LoginPage />} /> {/* Log in page */}
        <Route path="/signup" element={<SignUp />} /> {/* Sign up page */}
        <Route path="/create-profile" element={<ProtectedRoute><CreateProfile /></ProtectedRoute>} /> {/* Create profile */}
        <Route path="/edit-profile/:id" element={<ProtectedRoute><CreateProfile /></ProtectedRoute>} /> {/* Edit profile */}
        <Route path="/user-profiles" element={<ProtectedRoute><UserProfiles /></ProtectedRoute>} /> {/* User profiles */}
        <Route path="/create-profile/:id" element={<ProtectedRoute><CreateProfile /></ProtectedRoute>} />
        <Route path="/create-team" element={<TeamProfile />} />
        <Route path="/team-management/:id" element={<TeamManagement/>} />
        <Route path="/accept-invite/:invitationId" element={<AcceptInvitation/>}/>
        <Route path="/admin-generate-qr" element={<ProtectedRoute><AdminGenerateQR /></ProtectedRoute>} /> {/* Add profile */}
        <Route path="/car-details/:id" element={<CarDetails />} /> {/* Car details page with dynamic ID */}
      </Routes>
    </Router>
  );
}

export default App;
