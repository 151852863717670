import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from './firebase'; // Import Firebase Auth
import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';

function ProtectedRoute({ children }) {
  const [user, setUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setCheckingAuth(false); // Stop checking after the user is identified
    });

    return () => unsubscribe();
  }, []);

  if (checkingAuth) {
    return <p>Loading...</p>; // Optional loading state while checking
  }

  return user ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
